import { render, staticRenderFns } from "./changeVoucherNotices.html?vue&type=template&id=0761d820&scoped=true&"
import script from "./changeVoucherNotices.ts?vue&type=script&lang=ts&"
export * from "./changeVoucherNotices.ts?vue&type=script&lang=ts&"
import style0 from "./changeVoucherNotices.scss?vue&type=style&index=0&id=0761d820&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0761d820",
  null
  
)

/* custom blocks */
import block0 from "./changeVoucherNotices.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fhome%2Fchange-vouchers%2FchangeVoucher%2FchangeVoucherNotices%2FchangeVoucherNotices.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VCol,VRow})
