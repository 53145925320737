import { Component, Vue, Prop } from 'vue-property-decorator';
import { CONST } from '@/shared/utils/Constants';

@Component({ name: 'document-upload' })
export default class DocumentUpload extends Vue {
  @Prop({ default: false })
  private isLoading!: boolean;
  @Prop({ default: false })
  private isUploaded!: boolean;
  @Prop({ default: false })
  private disabled!: boolean;

  private allowedExtensions = 'pdf'; // wanted to do PROP with dynamic Extensions, but `Ext` messages get extensions in i18n message as {0},{1},{2},{3} and {4} for current VALUE (ie dynamic number of variables), so should write custom validation to have good and correct messages

  private document: any = null;
  get isDocumentSelected() {
    return this.document !== null;
  }
  private onChange(fileInfo: any) {
    const isInputHasFile = fileInfo && fileInfo.name ? true : false;
    this.$emit('checkInputFile', isInputHasFile, fileInfo);
  }

  upload() {
    console.log('this.document :>> ', this.document);
    // console.log('+this.document.size <= CONST.maxFileSize5MB :>> ', +this.document.size <= CONST.maxFileSize5MB);
    if (this.document && +this.document.size <= CONST.maxFileSize5MB && this.document.type?.includes('pdf')) {
      this.$emit('upload', {
        document: this.document,
        id: '',
      });
    }
  }
}
