export interface AaeType {
  id: number;
  index: number;
  i18nKey: string;
  name?: string;
}

export enum AaeTypeNameKey {
  credit_note = 'credit',
  cancel = 'cancel',
  value_date_change = 'value_date_change',
  contract_penalty_minus = 'penalty_minus',
  contract_penalty_plus = 'penalty_plus',
  debit = 'debit',
  rebooking = 'rebooking',
  cancel_reverse = 'cancel_reverse',
  bonus_minus = 'bonus_minus',
  bonus_plus = 'bonus_plus',
}

const RootI18nKey = 'aae_type.';

export const aaeTypeList: AaeType[] = [
  { id: 1, index: 2, i18nKey: RootI18nKey + AaeTypeNameKey.credit_note },
  { id: 2, index: 3, i18nKey: RootI18nKey + AaeTypeNameKey.cancel },
  { id: 3, index: 4, i18nKey: RootI18nKey + AaeTypeNameKey.value_date_change },
  { id: 4, index: 8, i18nKey: RootI18nKey + AaeTypeNameKey.contract_penalty_minus },
  { id: 5, index: 18, i18nKey: RootI18nKey + AaeTypeNameKey.contract_penalty_plus },
  { id: 6, index: 10, i18nKey: RootI18nKey + AaeTypeNameKey.debit },
  { id: 7, index: 5, i18nKey: RootI18nKey + AaeTypeNameKey.rebooking },
  { id: 8, index: 21, i18nKey: RootI18nKey + AaeTypeNameKey.cancel_reverse },
  { id: 9, index: 9, i18nKey: RootI18nKey + AaeTypeNameKey.bonus_minus },
  { id: 10, index: 19, i18nKey: RootI18nKey + AaeTypeNameKey.bonus_plus },
];

export default {
  aaeTypeList,
};

// 2	Gutschrift
// 3	Stornierung
// 4	Valutadatum Änderung
// 8	Vertragsstrafe negativ
// 8	Vertragsstrafe positiv
// 10	Belastung
// 20	Umbuchung
// 21	Stornierung-Aufhebung
