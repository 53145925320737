import { render, staticRenderFns } from "./About.html?vue&type=template&id=fc2ae838&scoped=true&"
import script from "./About.ts?vue&type=script&lang=ts&"
export * from "./About.ts?vue&type=script&lang=ts&"
import style0 from "./About.scss?vue&type=style&index=0&id=fc2ae838&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fc2ae838",
  null
  
)

/* custom blocks */
import block0 from "./About.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fabout%2FAbout.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports