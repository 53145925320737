import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import changeVoucherDocument, { ChangeVoucherDocument } from '@/shared/model/changeVoucherDocument';
import { CONST } from '@/shared/utils/Constants';
import { namespace } from 'vuex-class';
import DocumentUpload from '@/components/_common/document-upload/document-upload.vue';

const name = 'changeVoucherDocument-add-edit-dialog';
const logger = new Logger(name);
const documentsModule = namespace('document');
const changeVoucherDocumentModule = namespace('changeVoucherDocument');

@Component({ name: name, components: { DocumentUpload } })
export default class ChangeVoucherDocumentAddEditDialog extends Vue {
  @Ref('document-upload-cmp')
  private documentUploadCmpRef!: any;
  @Ref('observer-add-edit-changeVoucherDocument-form')
  private observerForm!: any;

  @changeVoucherDocumentModule.Action('updateChangeVoucherDocument')
  private actionUpdateChangeVoucherDocument!: any;

  @changeVoucherDocumentModule.Action('uploadDocument')
  private actionUploadDocument!: any;
  @changeVoucherDocumentModule.Action('downloadDocument')
  private actionDownloadDocument!: any;
  // TODO: (GSP-123) implement later
  // @changeVoucherDocumentModule.Action('deleteDocument')
  // private actionDeleteDocument!: any;
  // @changeVoucherDocumentModule.Mutation('setDocument')
  // private mutationSetDocument!: any;

  @Prop({ default: false })
  private dialog!: boolean;
  @Prop({ default: '' })
  private documentType!: string;
  @Prop({ default: '' })
  private parentId!: string;

  @Prop({ default: () => changeVoucherDocument.parse({}) })
  private value!: ChangeVoucherDocument;

  private documentData: any = {}; // Document = { ...DocumentEmpty };
  @PropSync('value', { default: '' })
  private model!: any; //Document

  // @Watch('value', {deep: true})
  @Watch('value')
  onUpdateValue(newV: ChangeVoucherDocument, oldV: ChangeVoucherDocument) {
    // console.log('onUpdateValue newVnewV:>> ',  Object.assign({},newV) );
    // console.log('onUpdateValue OLD :>> ',  Object.assign({},oldV) );
    this.changeVoucherDocument = Object.assign(this.changeVoucherDocument, newV);
    this.setDocumentFileInfoOnOpen();
  }
  @Watch('dialog')
  onDialogOpen(newV: boolean, oldV: boolean) {
    // console.log('onUpdateValue newVnewV:>> ',  Object.assign({},newV) );
    // console.log('onUpdateValue OLD :>> ',  Object.assign({},oldV) );'
    console.log('open dialog :>> ', newV);
    this.setDocumentFileInfoOnOpen();
  }
  private changeVoucherDocument: ChangeVoucherDocument = changeVoucherDocument.parse({});

  activeTab = 0;
  isLoading = false;

  created() {
    this.changeVoucherDocument = Object.assign(this.changeVoucherDocument, this.model);
    this.setDocumentFileInfoOnOpen();
  }

  get isEmptyChangeVoucherDocument() {
    return this.changeVoucherDocument.id == 0;
  }

  isLoadingDocument: boolean = false;
  private isDocUploaded = false;

  private documentFileInfo: {
    documentName: string;
    documentType: string;
    documentSize: string;
  } = {
    documentName: '',
    documentType: '',
    documentSize: '',
  };

  // private initValue(val: any) {
  //   this.model = Object.assign({}, val);
  // }

  // need this watcher to preload documentName and DocumentSize on dialog open, otherwise we will get `. (0.0 KB)`
  @Watch('dialog', { deep: true })
  async onPropDialogChanged(newV: any) {
    if (newV) {
      // let promiseAll = [];
      // const isHasDocument = this.changeVoucherDocument.document_file_id && this.changeVoucherDocument.document_file_id != CONST.emptyGuid;
      // this.isDocUploaded = isHasDocument ? true : false;
      // if (isHasDocument)
      //   promiseAll.push(
      //     this.actionGetDocument(this.model.id).then((response: any) => {
      //       this.documentData = response;
      //       this.setDocumentFileInfo(response);
      //       this.isDocUploaded = true;
      //       this.$emit('document:getData', response); // better emit result of `actionGetDocument` then make extra request from `*-documents` table
      //     })
      //   );
      // await Promise.all(promiseAll);
    }
  }

  get hasUploadedDocument() {
    return this.isDocUploaded;
  }

  get uploadedDocumentInfo() {
    if (!this.documentFileInfo.documentName) return '';
    return `${this.documentFileInfo.documentName}.${this.documentFileInfo.documentType} (${(
      +this.documentFileInfo.documentSize / 1000
    ).toFixed(1)} KB)`;
  }

  setDocumentFileInfoOnOpen() {
    const hasDocument =
      this.changeVoucherDocument.document_file_id && this.changeVoucherDocument.document_file_id != CONST.emptyGuid;
    if (hasDocument) {
      this.isDocUploaded = true;

      this.documentFileInfo!.documentName = this.changeVoucherDocument.document_name;
      this.documentFileInfo!.documentType = this.changeVoucherDocument.document_type;
      this.documentFileInfo!.documentSize = this.changeVoucherDocument.document_size;
    }
  }

  private setDocumentFileInfo(docFileInfo: any) {
    this.documentFileInfo!.documentName = docFileInfo.documentName;
    this.documentFileInfo!.documentType = docFileInfo.documentType;
    this.documentFileInfo!.documentSize = docFileInfo.documentSize;
    this.documentData = { ...this.documentData, ...this.documentFileInfo }; /// TODO: (LOW) write documentFileInfo in DocumentData (maybe we don't need that and delete in future)
  }

  async uploadDocument(payload: any) {
    logger.debug('uploadDocument');
    payload.id = this.model.id;
    // payload.parentId = this.parentId;
    this.isLoadingDocument = true;
    await this.actionUploadDocument(payload)
      .then((result: any) => {
        logger.debug('uploadDocument result :>> ', result);
        if (result.isValid) {
          this.model.file_id = result.id;
          this.setDocumentFileInfo(result.result); // `result.result` contains DocumentInfo (Name, Extension, Size) from successfully uploaded file on the Backend
          this.isDocUploaded = true;
          // this.$emit('document:uploaded'); // need to load list documents (not our case)
          this.$emit('document:getData', this.documentData); // better emit result of Document creation + File Upload here than make extra request from `*-documents` table
          // also Important to get the last changes when open dialog, if document was changed by someone else
        }
      })
      .finally(() => {
        this.isLoadingDocument = false;
        this.resetDocumentData();
        this.onClickedClose();
      });
  }

  get isFormEmpty() {
    return !this.changeVoucherDocument.title || !this.changeVoucherDocument.text;
  }

  private setDocumentInfo() {
    this.documentData!.type = this.documentType;
    this.documentData!.for = 'all'; // TODO: (EGRUP-143) temporary solution until find out logic about `FOR` field
    this.documentData.title = this.model.title;
  }

  async changeVoucherDocumentAddEditDialogOnUpdate() {
    this.changeVoucherDocument.change_voucher_id = +this.$route.params['changeVoucherId'];
    await this.actionUpdateChangeVoucherDocument(this.changeVoucherDocument).then((result: any) => {
      console.log('result :>> ', result);
      console.log('upload uploaduploaduploadupload:>> ');
      console.log('before changeVoucherDocument :>> ', Object.assign({}, this.changeVoucherDocument));
      this.model.id = result.idL;
      this.documentUploadCmpRef.upload();
      this.changeVoucherDocument = result.result;
      console.log('after changeVoucherDocument :>> ', Object.assign({}, this.changeVoucherDocument));
      // this.changeVoucherDocumentAddEditRef.$data.isLoading = false;
      // this.getChangeVoucherDocuments();
    });
  }

  private async fileInputChange(isInputHasFile: boolean, fileInfo: any) {
    if (isInputHasFile && fileInfo && fileInfo.size <= CONST.maxFileSize5MB) {
      logger.debug('fileInputChange');
      this.setDocumentInfo();
      await this.changeVoucherDocumentAddEditDialogOnUpdate()
        .then(async (result: any) => {
          logger.debug('onSave result:>> ', result);
          // this.model.id = result.id;
          // this.documentData = { ...result.result };
          // this.uploadDocumentBtnClick();
        })
        .catch((err) => {});
      // await this.actionUpdateDocument(this.documentData)
      //   .then(async (result: any) => {
      //     logger.debug('onSave result:>> ', result);
      //     this.model.id = result.id;
      //     this.documentData = { ...result.result };
      //     this.uploadDocumentBtnClick();
      //   })
      //   .catch((err: any) => {});
    }
  }
  uploadDocumentBtnClick() {
    this.documentUploadCmpRef.upload();
  }
  async downloadDocument() {
    await this.actionDownloadDocument(this.model!.file_id);
  }
  deleteDocument() {
    logger.debug('deleteDocument');
    // this.actionDeleteDocument(this.model.file_id).then(async (result: any) => {
    //   this.isDocUploaded = false;
    // });
    // this.model.parentId = '';
  }

  async onClickedUpdate() {
    this.isLoading = true;
    const result = await this.observerForm.validate();
    if (!result) {
      this.isLoading = false;
      return;
    }
    this.$emit('click:update', this.changeVoucherDocument);
  }

  onClickedClose() {
    this.resetDocumentData();
    this.resetDocumentFileData();
    this.observerForm.reset();
    this.$emit('click:close', { updateList: true });
  }

  /**
   * Reset `documentData` after successful upload, otherwise there is Bug when try to upload next document via dialog (BUG: ID saved without reset, so it does NOT create new document but UPDATE existing)
   */
  private resetDocumentData() {
    // this.documentData = {}; //{ ...DocumentEmpty };
  }

  /**
   * Clear `document-upload` Name/size data before closing the dialog to avoid showing wrong data for new dialog
   */
  private resetDocumentFileData() {
    this.documentFileInfo = {
      documentName: '',
      documentType: '',
      documentSize: '',
    };
  }
}
