import changeVoucherDocument, { ChangeVoucherDocument } from '@/shared/model/changeVoucherDocument';
import { OdataItems } from '@/shared/model/OdataItems';
import { SearchParams } from '@/shared/model/searchParams';
import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ChangeVoucherDocumentAddEditDialog from './changeVoucherDocument/changeVoucherDocument.vue';
import { CONST } from '@/shared/utils/Constants';
// import { DefaultViewHelper } from '../ViewHelper';
// import D4yTableMini from '@/components/d4y-table-mini/d4y-table-mini.vue';
import D4yTableMini from '@/components/d4y-table-mini/d4y-table-mini.vue';

import GeneralUtils from '@/shared/utils/generalUtils';

const name = 'changeVoucherDocument-view';
const changeVoucherDocumentModule = namespace('changeVoucherDocument');

@Component({ name: name, components: { ChangeVoucherDocumentAddEditDialog, D4yTableMini } })
export default class ChangeVoucherDocumentsComponent extends Vue {
  @changeVoucherDocumentModule.Action('getChangeVoucherDocuments') private actionGetchangeVoucherDocuments!: any;
  @changeVoucherDocumentModule.Getter('getChangeVoucherDocuments')
  private changeVoucherDocuments!: OdataItems<ChangeVoucherDocument>;
  @changeVoucherDocumentModule.Getter('getChangeVoucherDocumentsSearchParams')
  private changeVoucherDocumentsSearchParams!: SearchParams;
  @changeVoucherDocumentModule.Getter('getChangeVoucherDocumentsTotal')
  private changeVoucherDocumentsTotal!: number;
  @changeVoucherDocumentModule.Getter('getChangeVoucherDocumentsIsLoading')
  private changeVoucherDocumentsIsLoading!: boolean;
  @changeVoucherDocumentModule.Action('updateChangeVoucherDocument')
  private actionUpdateChangeVoucherDocument!: any;
  @changeVoucherDocumentModule.Action('deleteChangeVoucherDocument')
  private actionDeleteChangeVoucherDocument!: any;

  @Prop({ default: false })
  private disabled!: boolean;

  @Prop({ default: CONST.emptyGuid })
  private locationId!: string;

  @Ref('changeVoucherDocument-add-edit-ref')
  private changeVoucherDocumentAddEditRef!: ChangeVoucherDocumentAddEditDialog;

  async mounted() {
    var promiseAll = [this.getChangeVoucherDocuments()];
    await Promise.all(promiseAll);
  }
  private async getChangeVoucherDocuments() {
    await this.actionGetchangeVoucherDocuments({
      searchParams: this.changeVoucherDocumentsSearchParams,
      changeVoucherId: +this.$route.params['changeVoucherId'],
    });
  }
  private image = {
    src: 'cv_document.svg',
    header: '',
    height: 85,
    width: 140,
  };
  private downloadIcon = {
    src: 'pdf_download_document.svg',
    header: '',
    height: 32,
    width: 32,
  };

  @changeVoucherDocumentModule.Action('downloadDocument')
  private actionDownloadDocument!: any;
  async download(item: any) {
    item.isLoadingDocument = true;
    await this.actionDownloadDocument(item.document_file_id).finally(() => {
      item.isLoadingDocument = false;
    });
  }

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      width?: string;
      align?: string;
    }[] = [
      { text: this.$t('name'), value: 'title' },
      { text: this.$t('text'), value: 'text' },
      { text: this.$t('creator'), value: 'erstellt_von' },
      { text: this.$t('created_on'), value: 'erstellt_am' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
        align: 'center',
      },
    ];
    return headers;
  }

  get getFilterFields() {
    return this.changeVoucherDocumentsSearchParams.orClauseFieldsIds
      .map((x) => this.$t(GeneralUtils.camelToSnakeCase(x)))
      .toString();
  }
  private async changeVoucherDocumentsUpdateOptions(newVal: any, oldVal: any) {
    this.changeVoucherDocumentsSearchParams.dataOption = newVal;
    this.changeVoucherDocumentsSearchParams.filter = newVal.filter;
    if (newVal != oldVal && !this.changeVoucherDocumentsIsLoading) this.getChangeVoucherDocuments();
  }

  //#region AddEdit changeVoucherDocument
  changeVoucherDocumentAddEditDialog = {
    show: false,
    showDelete: false,
    model: { ...changeVoucherDocument.parse({}) },
  };

  onAdd() {
    this.add();
  }

  add() {
    this.changeVoucherDocumentAddEditDialog.model = { ...changeVoucherDocument.parse({}) };
    this.changeVoucherDocumentAddEditDialog.show = true;
  }

  async changeVoucherDocumentAddEditDialogOnClose(data: any) {
    this.changeVoucherDocumentAddEditDialog.model = { ...changeVoucherDocument.parse({}) };
    this.changeVoucherDocumentAddEditDialog.show = false;
    if (data?.updateList) {
      this.getChangeVoucherDocuments();
    }
  }

  editChangeVoucherDocument(editchangeVoucherDocument: ChangeVoucherDocument) {
    this.changeVoucherDocumentAddEditDialog.model = { ...editchangeVoucherDocument };
    this.changeVoucherDocumentAddEditDialog.show = true;
  }

  async changeVoucherDocumentAddEditDialogOnUpdate(changeVoucherDocument: ChangeVoucherDocument) {
    this.changeVoucherDocumentAddEditDialog.show = false;
    changeVoucherDocument.change_voucher_id = +this.$route.params['changeVoucherId'];
    await this.actionUpdateChangeVoucherDocument(changeVoucherDocument).then(({ id }: any) => {
      // this.changeVoucherDocumentAddEditRef.$data.isLoading = false;
      this.getChangeVoucherDocuments();
    });
  }

  deleteChangeVoucherDocument(editedchangeVoucherDocument: ChangeVoucherDocument) {
    this.$confirm
      .open(`${this.$t('delete_dialog_title')}`, `${this.$t('delete_dialog_text')}`, {
        cancelText: this.$t('delete_dialog_cancel'),
        okText: this.$t('delete_dialog_ok'),
      })
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteChangeVoucherDocument(editedchangeVoucherDocument.id).then(() => {
            this.getChangeVoucherDocuments();
          });
        }
      });
  }

  //#endregion
}
