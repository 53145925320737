
import changeVoucherNotice, { ChangeVoucherNotice } from '@/shared/model/changeVoucherNotice';
import { OdataItems } from '@/shared/model/OdataItems';
import { SearchParams } from '@/shared/model/searchParams';
import Vue from 'vue';
import { Component, Prop, Ref, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import ChangeVoucherNoticeAddEditDialog from './changeVoucherNotice/changeVoucherNotice.vue';
import { CONST } from '@/shared/utils/Constants';
// import { DefaultViewHelper } from '../ViewHelper';
import D4yTableMini from '@/components/d4y-table-mini/d4y-table-mini.vue';
import GeneralUtils from '@/shared/utils/generalUtils';

const name = 'changeVoucherNotice-view';
const changeVoucherNoticeModule = namespace('changeVoucherNotice');

@Component({ name: name, components: { ChangeVoucherNoticeAddEditDialog, D4yTableMini } })
export default class ChangeVoucherNoticesComponent extends Vue {
  @changeVoucherNoticeModule.Action('getChangeVoucherNotices') private actionGetchangeVoucherNotices!: any;
  @changeVoucherNoticeModule.Getter('getChangeVoucherNotices')
  private changeVoucherNotices!: OdataItems<ChangeVoucherNotice>;
  @changeVoucherNoticeModule.Getter('getChangeVoucherNoticesSearchParams')
  private changeVoucherNoticesSearchParams!: SearchParams;
  @changeVoucherNoticeModule.Getter('getChangeVoucherNoticesTotal')
  private changeVoucherNoticesTotal!: number;
  @changeVoucherNoticeModule.Getter('getChangeVoucherNoticesIsLoading')
  private changeVoucherNoticesIsLoading!: boolean;
  @changeVoucherNoticeModule.Action('updateChangeVoucherNotice')
  private actionUpdateChangeVoucherNotice!: any;
  @changeVoucherNoticeModule.Action('deleteChangeVoucherNotice')
  private actionDeleteChangeVoucherNotice!: any;

  @Prop({ default: false })
  private disabled!: boolean;

  @Prop({ default: CONST.emptyGuid })
  private locationId!: string;

  @Ref('changeVoucherNotice-add-edit-ref') private changeVoucherNoticeAddEditRef!: ChangeVoucherNoticeAddEditDialog;

  async mounted() {
    var promiseAll = [this.getChangeVoucherNotices()];
    await Promise.all(promiseAll);
  }
  private async getChangeVoucherNotices() {
    await this.actionGetchangeVoucherNotices({
      searchParams: this.changeVoucherNoticesSearchParams,
      changeVoucherId: +this.$route.params['changeVoucherId'],
    });
  }
  private image = {
    src: 'cv_note.svg',
    header: '',
    height: 85,
    width: 140,
  };

  get headers() {
    let headers: {
      text: string | any;
      value: string;
      sortable?: boolean;
      class?: string | string[];
      cellClass?: string | string[];
      width?: string;
      align?: string;
    }[] = [
      // { text: this.$t('name'), value: 'title' },
      { text: this.$t('text'), value: 'text', cellClass: 'min-w-50p' },
      { text: this.$t('creator'), value: 'erstellt_von' },
      { text: this.$t('created_on'), value: 'erstellt_am' },
      {
        text: this.$t('actions'),
        value: 'actions',
        sortable: false,
        width: '1%',
        align: 'center',
      },
    ];
    return headers;
  }

  get getFilterFields() {
    return this.changeVoucherNoticesSearchParams.orClauseFieldsIds
      .map(x => this.$t(GeneralUtils.camelToSnakeCase(x)))
      .toString();
  }
  private async changeVoucherNoticesUpdateOptions(newVal: any, oldVal: any) {
    this.changeVoucherNoticesSearchParams.dataOption = newVal;
    this.changeVoucherNoticesSearchParams.filter = newVal.filter;
    if (newVal != oldVal && !this.changeVoucherNoticesIsLoading) this.getChangeVoucherNotices();
  }

  //#region AddEdit changeVoucherNotice
  changeVoucherNoticeAddEditDialog = {
    show: false,
    showDelete: false,
    model: { ...changeVoucherNotice.parse({}) },
  };

  onAdd() {
    this.add();
  }

  add() {
    this.changeVoucherNoticeAddEditDialog.model = { ...changeVoucherNotice.parse({}) };
    this.changeVoucherNoticeAddEditDialog.show = true;
  }

  async changeVoucherNoticeAddEditDialogOnClose(editedchangeVoucherNotice: ChangeVoucherNotice) {
    this.changeVoucherNoticeAddEditDialog.model = { ...changeVoucherNotice.parse({}) };
    this.changeVoucherNoticeAddEditDialog.show = false;
  }

  editChangeVoucherNotice(editchangeVoucherNotice: ChangeVoucherNotice) {
    this.changeVoucherNoticeAddEditDialog.model = { ...editchangeVoucherNotice };
    this.changeVoucherNoticeAddEditDialog.show = true;
  }

  async changeVoucherNoticeAddEditDialogOnUpdate(changeVoucherNotice: ChangeVoucherNotice) {
    this.changeVoucherNoticeAddEditDialog.show = false;
    changeVoucherNotice.change_voucher_id = +this.$route.params['changeVoucherId'];
    await this.actionUpdateChangeVoucherNotice(changeVoucherNotice).then(({ id }: any) => {
      this.changeVoucherNoticeAddEditRef.$data.isLoading = false;
      this.getChangeVoucherNotices();
    });
  }

  deleteChangeVoucherNotice(editedchangeVoucherNotice: ChangeVoucherNotice) {
    this.$confirm
      .open(
        `${this.$t('delete_dialog_title')}`,
        `${this.$t('delete_dialog_text')}`,
        {
          cancelText: this.$t('delete_dialog_cancel'),
          okText: this.$t('delete_dialog_ok'),
        }
      )
      .then(async (response: any) => {
        if (response) {
          await this.actionDeleteChangeVoucherNotice(editedchangeVoucherNotice.id).then(() => {
            this.getChangeVoucherNotices();
          });
        }
      });
  }

  //#endregion
}
