import { render, staticRenderFns } from "./changeVoucherDocuments.html?vue&type=template&id=af4d0b4c&"
import script from "./changeVoucherDocuments.ts?vue&type=script&lang=ts&"
export * from "./changeVoucherDocuments.ts?vue&type=script&lang=ts&"
import style0 from "./changeVoucherDocuments.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* custom blocks */
import block0 from "./changeVoucherDocuments.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fhome%2Fchange-vouchers%2FchangeVoucher%2FchangeVoucherDocuments%2FchangeVoucherDocuments.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VCard,VCardText,VCardTitle,VCol,VImg,VRow})
