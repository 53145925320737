import { Component, Vue, Prop, PropSync, Watch } from 'vue-property-decorator';
import UserMini from '@/components/user/mini/user-mini.vue';
import dataOptions, { DataOptions } from '@/shared/model/DataOptions';
import { Logger } from 'fsts';
import DateUtils from '@/shared/utils/DateUtils';
import { TranslateResult } from 'vue-i18n';
import GeneralUtils from '@/shared/utils/generalUtils';
import DateField from '../_common/date-field/DateField.vue';

const logger = new Logger('components.position-list');
const dateUtils = DateUtils;

export type AnyObject = Record<string, any>;

@Component({ name: 'd4y-table-mini', components: { DateField } })
export default class D4yTableMini extends Vue {
  @Prop({ default: false })
  private disabled!: boolean;
  @Prop({ default: false })
  private showSelect!: boolean;
  @Prop({ default: () => false })
  private showExpand!: boolean;
  @Prop({ default: false })
  private singleSelect!: boolean;
  @PropSync('selected', {
    default: () => [],
  })
  private selectedModel!: Array<any>;

  @Prop()
  private items!: AnyObject[];

  @PropSync('expanded', {
    default: () => [],
  })
  private expandedItems!: Array<any>;

  @Prop()
  private SwitchView!: string;

  @Prop({ default: false })
  private isNameAsRef!: boolean;
  @Prop({ default: false })
  private isPersonalNumberAsRef!: boolean;
  @Prop({ default: false })
  private isDocumentFullNameAsRef!: boolean;
  @Prop({ default: false })
  private isFirstNameAsRef!: boolean;
  @Prop({ default: false })
  private isTextAsRef!: boolean;
  @Prop({ default: false })
  private isTitleAsRef!: boolean;

  @Prop({ default: true })
  private flatInner!: boolean;
  @Prop({ default: false })
  haveMoreActions!: boolean;

  @Prop({ default: false })
  private outlined!: boolean;

  @PropSync('options', {
    default: (): DataOptions => {
      return dataOptions.getDefault;
    },
  })
  private optionsDto!: DataOptions;

  @Prop({ default: () => [''] })
  private filterFields!: string[];

  // TODO:(EGRUP-79) same @Prop here and in the `tree-navigator` component probably better use Vuex state
  @Prop({
    default: () => {
      return undefined;
    },
  })
  private image?: {
    src: string;
    header: string;
    height: number;
    width: number;
  };

  @Prop()
  private breadcrumbs!: any;
  @Prop({ default: false })
  private AllowAdd!: boolean;
  @Prop({ default: false })
  private AllowDelete!: boolean;
  @Prop({ default: true })
  private AllowSearch!: boolean;
  @Prop({ default: true })
  private allowDeleteCreatorUserIdCompare!: string;
  @Prop({ default: '' })
  private allowEditCreatorUserIdCompare!: string;

  @Prop({ default: false })
  private loading!: boolean;

  @Prop()
  private serverItemsLength!: number;

  @Prop({ default: false })
  allowDelete!: boolean;

  @Prop({ default: false })
  allowEdit!: boolean;

  @Prop({ default: false })
  isEmbedInD4yInfo!: boolean;

  @Prop({ default: 7 })
  pa!: number;
  @Prop({ default: true })
  flat!: boolean;
  @Prop({ default: false })
  isEditableText!: boolean;
  @Prop({ default: false })
  isEditableOrderNumber!: boolean;

  @Watch('optionsDto.filter')
  public onOptionsFilterChanged(newVal: any, oldVal: any) {
    logger.debug(`----filter:${oldVal}->${newVal}`);
    this.$emit('update:options', this.optionsDto);
  }

  // make 2 request to backend if in global watch by object
  // when update sortDesc sortBy to changed
  @Watch('optionsDto.sortBy', { deep: true })
  public onOptionsSortByChanged(newVal: string[], oldVal: string[]) {
    logger.debug(`----sortBy:${oldVal}->${newVal}`);

    if (JSON.stringify(newVal) != JSON.stringify(oldVal)) {
      logger.debug('+');
      this.$emit('update:options', this.optionsDto);
    }
  }
  @Watch('optionsDto.sortDesc')
  public onOptionssortDescChanged(newVal: any, oldVal: any) {
    const oldValAsString = JSON.stringify(oldVal);
    const newValAsString = JSON.stringify(newVal);
    logger.debug(`-od-sortDesc:${oldValAsString}->${newValAsString}`);
    if (oldValAsString != newValAsString) {
      logger.debug('+');
      this.$emit('update:options', this.optionsDto);
    }
  }
  @Watch('optionsDto.page')
  public onOptionsPageChanged(newVal: any, oldVal: any) {
    logger.debug(`----page:${oldVal}->${newVal}`);
    this.$emit('update:options', this.optionsDto);
  }
  get isSortByEmpty() {
    return this.optionsDto.sortBy.length < 1;
  }
  @Prop({ default: () => [{ text: 'email', value: 'email' }] })
  private headers!: {
    text: string | any;
    value: string;
    sortable?: boolean;
    width?: string;
  }[];

  @Prop({ default: 'primary' })
  private color!: string;
  get searchListLabel() {
    let label = '';
    if (this.filterFields && this.filterFields.length > 0)
      label = `${this.$t('search', { 0: this.filterFields })}`;
    logger.debug(`search label:${this.filterFields} -> ${label}`);
    return label;
  }

  /**
   * Check if named slot used in the `d4y-table` implementation in parent components (like `groups`[groups_list in Sketch], etc)
   * @param name (name of the slot)
   */
  private hasSlot(name: string) {
    return !!this.$slots[name] || !!this.$scopedSlots[name];
  }

  formatDate(date: any) {
    var sdate = dateUtils.formatDateTimeISO(date);
    logger.debug(sdate);
    return sdate;
  }

  private formatTime(e: any) {
    // format time if 1-2 characters to full time, like `9` to `9:00` OR `12` to `12:00`
    if (e && e.target && e.target.value && e.target.value.length <= 2) {
      let timeValue = e.target.value;
      timeValue = timeValue.replace(/\D/g, ''); // delete all NON-numeric chars
      if (timeValue.length > 0) {
        timeValue = `${timeValue}:00`;
        e.target.value = timeValue;
        // this.setTimeModelValue(e.target);
      }
    }
  }

  private itemExpanded(event: { item: any; value: boolean }) {
    this.$emit('item-expanded', event);
  }
}
