import { Component, Prop, Ref, Vue, Watch } from 'vue-property-decorator';
import { Logger } from 'fsts';
import changeVoucherNotice, { ChangeVoucherNotice } from '@/shared/model/changeVoucherNotice';

const name = 'changeVoucherNotice-add-edit-dialog';
const logger = new Logger(name);

@Component({ name: name })
export default class ChangeVoucherNoticeAddEditDialog extends Vue {
  @Prop({ default: false })
  private dialog!: boolean;

  @Prop({ default: () => changeVoucherNotice.parse({}) })
  private value!: ChangeVoucherNotice;

  @Watch('value')
  onUpdateValue(newV: ChangeVoucherNotice, oldV: ChangeVoucherNotice) {
    this.changeVoucherNotice = Object.assign(this.changeVoucherNotice, newV);
  }
  private changeVoucherNotice: ChangeVoucherNotice = changeVoucherNotice.parse({});

  @Ref('observer-add-edit-changeVoucherNotice-form') private observerForm!: any;

  activeTab = 0;
  isLoading = false;

  get isEmptyChangeVoucherNotice() {
    return this.changeVoucherNotice.id == 0;
  }

  async onClickedUpdate() {
    this.isLoading = true;
    const result = await this.observerForm.validate();
    if (!result) {
      this.isLoading = false;
      return;
    }
    this.$emit('click:update', this.changeVoucherNotice);
  }

  onClickedClose() {
    this.$emit('click:close');
  }
}
