import { Component, Ref, Vue } from 'vue-property-decorator';
import { Logger } from 'fsts';
import { namespace } from 'vuex-class';
import changeVoucher, { ChangeVoucher } from '@/shared/model/changeVoucher';
import D4yEdit from '@/components/d4y-edit/d4y-edit.vue';
import { CONST } from '@/shared/utils/Constants';
import { ROUTES } from '@/router/routesEnum';
import DateUtils from '@/shared/utils/DateUtils';
import { OdataItems } from '@/shared/model/OdataItems';
import { AaeType, AaeTypeNameKey, aaeTypeList } from '@/shared/model/smallPayloadModels/aaeType';
import {
  ChangeReasonNameKey,
  ChangeReasonType,
  changeReasonList,
} from '@/shared/model/smallPayloadModels/changeReason';
import { changevoucherTypeList } from '@/shared/model/smallPayloadModels/changevoucherType';
import { DocumentType, documentTypeList } from '@/shared/model/smallPayloadModels/documentType';
import SupplierTooltip from '../../zr-documents/search-form/supplier-tooltip/supplier-tooltip.vue';
import DateField from '@/components/_common/date-field/DateField.vue';
import { changevoucherInternalStatusList } from '@/shared/model/smallPayloadModels/changevoucherInternalStatus';
import { Route } from 'vue-router';
import ChangeVoucherDocumentsComponent from './changeVoucherDocuments/changeVoucherDocuments.vue';
import ChangeVoucherNoticesComponent from './changeVoucherNotices/changeVoucherNotices.vue';
import html2canvas from 'html2canvas';
import GeneralUtils from '@/shared/utils/generalUtils';
import DocumentPreview from '../../document-preview/document-preview.vue';
import NumberUtils from '@/shared/utils/numberUtils';
import cngvoucherSearchData, { ChangeVoucherSearchData } from '@/shared/model/smallPayloadModels/changeVoucherSearchData';

const name = 'changeVoucher-edit-dialog';
const logger = new Logger(name);
const changeVoucherModule = namespace('changeVoucher');
const invoiceModule = namespace('invoice');
const authModule = namespace('auth');
const delay = GeneralUtils.delay; // delay function

@Component({
  name: name,
  components: {
    D4yEdit,
    SupplierTooltip,
    DateField,
    ChangeVoucherDocumentsComponent,
    ChangeVoucherNoticesComponent,
    DocumentPreview,
  },
})
export default class ChangeVoucherEditDialog extends Vue {
  @changeVoucherModule.Action('getChangeVoucher') private actionGetChangeVoucher!: any;
  @changeVoucherModule.Action('updateChangeVoucher')
  private actionUpdateChangeVoucher!: any;
  @changeVoucherModule.Action('creaateChangeVoucher')
  private actionCreaateChangeVoucher!: any;
  @changeVoucherModule.Getter('getChangeVouchersItems')
  private changeVouchers!: Array<ChangeVoucher>;
  @changeVoucherModule.Action('getChangeVoucherDetailsPdfReport')
  private actionGetChangeVoucherDetailsPdfReport!: any;
  // @changeVoucherModule.Getter('getChangeVoucher')
  // private changeVoucher!: ChangeVoucher;
  // @changeVoucherManagement.Getter('getChangeVoucherRoles')
  // private roles!: any;

  @invoiceModule.Action('getInvoiceByDocNumber')
  private actionGetInvoiceByDocNumber!: any;
  @invoiceModule.Action('getInvoiceSuppliers')
  private actionGetInvoiceSuppliers!: any;
  @invoiceModule.Getter('getInvoiceSuppliers')
  private getInvoiceSuppliers!: any;
  @invoiceModule.Action('getInvoiceMembers')
  private actionGetInvoiceMembers!: any;
  @invoiceModule.Getter('getInvoiceMembers')
  private getInvoiceMembers!: any;
  @invoiceModule.Action('getInvoicesForVoucher')
  private actionGetInvoicesForVoucher!: any;

  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroupGetter!: any;
  @authModule.Getter('zrNummer')
  private zrNummer!: any;
  @authModule.Getter('zrNummerWithRoles')
  private zrNummerWithRoles!: any;
  @authModule.Getter('supplierTooltipInfo')
  private supplierTooltipInfo!: any;
  @authModule.Getter('isUserOnlyGs')
  private isUserOnlyGs!: any;

  @authModule.Getter('isUserInAzureAdminGroup')
  private isUserInAzureAdminGroup!: any;
  @authModule.Getter('isUserSupport')
  private isUserSupport!: any;
  @authModule.Getter('isUserGsUser')
  private isUserGsUser!: any;
  @authModule.Getter('isUserHasRightsForVouchers')
  private isUserHasRightsForVouchers!: any;

  isFormLoaded = false;
  private isGeneratingPdfReport = false;
  private dmsInvoiceNr = '';

  async created() {
    const changeVoucherId = this.$route.params['changeVoucherId'];
    if (changeVoucherId != '0') {
      await this.loadChangeVoucher();
      this.isSkontierfaehig = (this.changeVoucher.aae_typ != 2 && this.changeVoucher.aae_typ != 10) ? true : false;
    } else {
      this.isFormLoaded = true;
      this.changeVoucher = changeVoucher.parse({});
      // show empty  brutto/netto only on creation
      this.changeVoucher.gross_amount = undefined;
      this.changeVoucher.net_amount = undefined;
      this.changeVoucher.tax_rate = undefined;
      this.changeVoucher.id = 0;
      let twoWeeksAfterFriday = this.getNextDayOfWeek(new Date()).toISOString();
      this.changeVoucher.due_date = twoWeeksAfterFriday;
    }
    let list = aaeTypeList.map((x: any) => {
      return { id: x.index, name: `${this.$t(x.i18nKey)}` };
    });
    console.log('aaeTypeList :>> ', aaeTypeList);
    console.log('list :>> ', list);
    var promiseAll = [this.getSuppliers(), this.getMembers()];
    await Promise.all(promiseAll);
    const senderNumber = this.$route.params['senderNumber'];
    const dmsNr = this.$route.params['dms_nr'];
    if (!!dmsNr) {
      this.changeVoucher.dms_nr_invoice = dmsNr;
      this.dmsInvoiceNr = dmsNr;
    }

    if (this.$route.params['senderNumber'] != undefined) {
      const documentNumber = this.$route.params['documentNumber'];
      let documentNumber1 = this.$route.params['documentNumber'].toString().trim();
      const externalDocumentReference = this.$route.params['externalDocumentReference'].toString().trim();
      const receiverNumber = this.$route.params['receiverNumber'];
      let documentNumberCV = documentNumber1.toString().trim();
      this.changeVoucher.sender_number = senderNumber;
      this.loadInvoices(null);
      this.changeVoucher.external_document_reference = externalDocumentReference;
      this.changeVoucher.document_number = documentNumberCV;
      this.changeVoucher.receiver_number = receiverNumber;
    }
  }

  async mounted() {
    if ((!this.isUserInAzureAdminGroup && !this.isUserGsUser) || !this.isUserHasRightsForVouchers) {
      this.goToVoucherList();
    }
  }

  private changeVoucher: any = changeVoucher.parse({});

  @Ref('observer-add-edit-changeVoucher-form') private observerForm!: any;

  activeTab: any = null; //0;
  isLoading = false;
  titleInfo = 'empty';
  voucherInvoices = [];

  getInvoiceTypeName(value: number) {
    //console.log('-------------------get InvoiceTypeName----------------  >>>:value   ', value);
    const invoiceDocType = documentTypeList.find((x: DocumentType) => x.index == value);
    if (invoiceDocType) {
      let i18key = invoiceDocType.i18nKey;
      let formattedResult = i18key ? `${this.$t(i18key)} (${value})` : value;
      return formattedResult;
    }
    return value;
  }

  loadInvoices(value: any) {
    console.log(
      '----------------load Invoices-------------------  >>>:value ',
      value,
      ' value != null ? value.id ',
      value != null ? value.identNr : 'Mitglied'
    );
    const payload = {
      senderNumber: value != null ? value.id : this.changeVoucher.sender_number,
      zrNummer: this.zrNummer,
      isUserInAzureAdminGroup: this.isUserInAzureAdminGroupGetter,
    };
    this.changeVoucher.sender_number = value != null ? value.id : this.changeVoucher.sender_number;
    this.changeVoucher.name_lieferant =
      value != null
        ? value.supplierName
        : this.getInvoiceSuppliers.length > 0
        ? this.getInvoiceSuppliers.find((item: any) => item.id == this.changeVoucher.sender_number).supplierName
        : '';
    this.actionGetInvoicesForVoucher(payload)
      .then((result: any) => {
        this.voucherInvoices = result;
      })
      .catch((err: any) => {
        logger.error(err);
      });
  }

  //#region (GSP-118) Show fields logic region
  get isVertragAaeTypeSelected() {
    return this.changeVoucher.aae_typ == 8 || this.changeVoucher.aae_typ == 18; // selected `Vertragsstrafe +/-`
  }

  get isUmbuchungAaeTypeSelected() {
    return this.changeVoucher.aae_typ == 20; // selected `Umbuchung`
  }

  get isUmbuchungStornierung() {
    return this.changeVoucher.aae_typ == 5 || this.changeVoucher.aae_typ == 21; // selected `Umbuchung`
  }

  get isShowBeleg() {
    return this.changeVoucher.aae_typ != 20; // not `Umbuchung`
  }

  get isShowDueDateAndCurrency() {
    return true; //this.changeVoucher.aae_typ != 20 && this.changeVoucher.aae_typ != 21; // not `Umbuchung` and not `Stornierung-Aufhebung`
  }
  get isHideForUmbuchAndStorno() {
    return this.isShowDueDateAndCurrency;
  }
  get isHideForVertragstrafe() {
    return this.changeVoucher.aae_typ != 8 && this.changeVoucher.aae_typ != 18; // not `Vertragsstrafe
  }
  get isShowSkontoField() {
    return (
      this.changeVoucher.aae_typ != 8 &&
      this.changeVoucher.aae_typ != 18 &&
      this.changeVoucher.aae_typ != 20 &&
      this.changeVoucher.aae_typ != 21
    ); // not `Vertragsstrafe`, not `Umbuchung` and not `Stornierung-Aufhebung`
  }

  get isShowSkontoCheckbox() {
    //return true;
    // let showSkontoCheckbox : boolean = false;
    // if (this.changeVoucher.aae_typ == 2 || this.changeVoucher.aae_typ == 10) {
    //   showSkontoCheckbox=true;
    // }
    // if ((this.changeVoucher.external_document_reference == null || this.changeVoucher.external_document_reference?.length == 0) && (this.changeVoucher.aae_typ == 9 || this.changeVoucher.aae_typ == 19)) {
    console.log(
      '-------------is ShowSkontoCheckbox----------------------  >>>:changeVoucher.id?.length    ',
      this.changeVoucher.id?.length
    );
    //   showSkontoCheckbox=true;
    // }
    return (
      this.changeVoucher.aae_typ == 2 ||
      this.changeVoucher.aae_typ == 10 ||
      this.changeVoucher.aae_typ == 9 ||
      this.changeVoucher.aae_typ == 19
    ); // Only `Gutschrift` or `Belastung`
  }

  get isMembers() {
    let readonlyMembers: boolean = true;
    if (this.changeVoucher.aae_typ == 5) {
      readonlyMembers = false;
    } else if (
      this.changeVoucher.external_document_reference == null ||
      this.changeVoucher.external_document_reference?.length == 0
    ) {
      readonlyMembers = false;
    }
    return readonlyMembers;
  }

  get isIdAndInvoiceNumber() {
    if (this.changeVoucher.external_document_reference?.length == 0 && this.changeVoucher.cv_belegnr?.length != 0) {
      return false;
    } else {
      return true;
    }
  }

  //#endregion

  currentInvoiceTypeNum = 0;
  selectInvoice(value: any) {
    if (value) {
      this.changeVoucher.attachment = value.attachment;
      this.currentInvoiceTypeNum = value.documentType;
      this.changeVoucher.document_number = value.documentNumber;
      this.changeVoucher.external_document_reference = value.externalDocumentReference;
    }

    if (this.changeVoucher.aae_typ_id) {
      this.getInvoiceIfSelected('');
    }
  }

  get noInvoiceSelected() {
    return !this.changeVoucher.document_number && !this.changeVoucher.external_document_reference;
  }

  get isBetragBrutto() {
    let readonlyBetragBrutto: boolean = false;
    if (
      this.changeVoucher.aae_typ == 3 ||
      this.changeVoucher.aae_typ == 4 ||
      this.changeVoucher.aae_typ == 5 ||
      this.changeVoucher.aae_typ == 8 ||
      this.changeVoucher.aae_typ == 18 ||
      this.changeVoucher.aae_typ == 21
    ) {
      readonlyBetragBrutto = true;
    }
    // else if (this.changeVoucher.aae_typ == 8 || this.changeVoucher.aae_typ == 18) {
    //   readonlyBetragBrutto = true;
    // } else if (this.changeVoucher.aae_typ == 9 || this.changeVoucher.aae_typ == 19) {
    //   readonlyBetragBrutto = false;
    // } else {
    //   readonlyBetragBrutto = false;
    // }
    return readonlyBetragBrutto;
  }

  get isBetragNetto() {
    let readonlyBetragNetto: boolean = false;
    if (
      this.changeVoucher.aae_typ == 3 ||
      this.changeVoucher.aae_typ == 4 ||
      this.changeVoucher.aae_typ == 5 ||
      this.changeVoucher.aae_typ == 21
    ) {
      readonlyBetragNetto = true;
    }
    //  else if (!(this.changeVoucher.external_document_reference == null || this.changeVoucher.external_document_reference?.length == 0)) {
    //   readonlyBetragNetto = true;
    // }
    return readonlyBetragNetto;
  }

  get isBelegDatum() {
    let readonlyBelegDatum: boolean = false;
    if (
      !(
        this.changeVoucher.external_document_reference == null ||
        this.changeVoucher.external_document_reference?.length == 0
      )
    ) {
      readonlyBelegDatum = true;
    }

    return readonlyBelegDatum;
  }

  get isFaelligkeitsDatum() {
    let readonlyFaelligkeitsdatum: boolean = false;
    if (this.changeVoucher.aae_typ == 3 || this.changeVoucher.aae_typ == 5 || this.changeVoucher.aae_typ == 21) {
      readonlyFaelligkeitsdatum = true;
    }

    return readonlyFaelligkeitsdatum;
  }

  get isFormReadOnly() {
    return this.changeVoucher.status != 0 && this.changeVoucher.status != 4;
  }

  selectAeeType(value: any) {
    if (value != null) {
      this.changeVoucher.aae_typ = value.index;
      this.changeVoucher.aae_typ_id = value.id; // to show value in autocomplete
    }
    if (
      this.changeVoucher.id?.length == 0 &&
      (this.changeVoucher.external_document_reference == null ||
        this.changeVoucher.external_document_reference?.length == 0) &&
      (this.changeVoucher.aae_typ == 9 || this.changeVoucher.aae_typ == 19)
    ) {
      this.changeVoucher.cv_skontosatz = 0;
      this.changeVoucher.cv_skonto = false;
      this.isSkontierfaehig = true;
    }
    this.isSkontierfaehig = this.changeVoucher.aae_typ != 2 && this.changeVoucher.aae_typ != 10 ? true : false;
    this.getInvoiceIfSelected('');
  }
  currentInvoiceData: any = {};
  getInvoiceIfSelected(val: any) {
    console.log('val :>> ', val);
    if (!!this.changeVoucher.document_number && !!this.changeVoucher.external_document_reference) {
      this.actionGetInvoiceByDocNumber(this.changeVoucher.document_number)
        .then((result: any) => {
          console.log('result :>> ', result);
          this.currentInvoiceData = result;
          this.populateDataFromRealInvoice();
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
  }

  private clearableMembers = false;
  private isSkontierfaehig = false;
  populateDataFromRealInvoice() {
    if (this.changeVoucher.aae_typ == 3 || this.changeVoucher.aae_typ == 5 || this.changeVoucher.aae_typ == 21) {
      let twoWeeksAfterFriday = this.getNextDayOfWeek(new Date()).toISOString();
      let dateValue: Date = new Date(this.currentInvoiceData.faelligkeitsdatum);
      let dateValue1: Date = new Date(Date.now());
      this.changeVoucher.due_date =
        dateValue >= dateValue1 ? this.currentInvoiceData.faelligkeitsdatum : twoWeeksAfterFriday;
    }
    this.changeVoucher.receiver_number = this.currentInvoiceData.zr_nr_debitor;
    this.changeVoucher.cv_skontosatz = this.currentInvoiceData.skontosatz;
    this.changeVoucher.erfassungs_datum = this.currentInvoiceData.belegdatum;

    this.changeVoucher.currency =
      this.currentInvoiceData.waehrung?.length != 0 || this.currentInvoiceData.waehrung != undefined
        ? this.currentInvoiceData.waehrung?.length != 0 && this.currentInvoiceData.waehrung != 'euro'
          ? this.currentInvoiceData.waehrung
          : 'EUR'
        : 'EUR';
    if (
      this.changeVoucher.aae_typ == 3 ||
      this.changeVoucher.aae_typ == 4 ||
      this.changeVoucher.aae_typ == 5 ||
      this.changeVoucher.aae_typ == 21
    ) {
      this.changeVoucher.tax_rate = this.currentInvoiceData.mwst_satz;
      this.changeVoucher.cv_skonto = this.currentInvoiceData.skontofaehig == 1 ? true : false;
      this.changeVoucher.gross_amount = (this.currentInvoiceData.brutto || 0).toLocaleString(this.$i18n.locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      this.changeVoucher.net_amount = this.currentInvoiceData.netto;
      this.changeVoucher.net_amount = (this.currentInvoiceData.netto || 0).toLocaleString(this.$i18n.locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    } else if (this.changeVoucher.aae_typ == 8 || this.changeVoucher.aae_typ == 18) {
      this.changeVoucher.cv_skonto = false;
      this.changeVoucher.tax_rate = 0;
    } else if (this.changeVoucher.aae_typ == 9 || this.changeVoucher.aae_typ == 19) {
      this.changeVoucher.cv_skonto = false;
    }

    if (this.changeVoucher.aae_typ == 5) {
      this.clearableMembers = true;
    } else {
      this.clearableMembers = false;
    }
  }

  // should specify formula from Slawa
  // TODO: only for DE for now + specify should calculate fields that hidden like Netto when Brutto present (see matrix)
  changeVoucherBrutto(val: string) {
    let format = val.replace(',', '.');
    let splitted = format.split('.');
    if (splitted.length > 1) {
      let last = splitted.pop();
      format = splitted.join('') + '.' + last;
    }
    if (format.endsWith('.')) {
      format = format.slice(0, -1);
    }
   
    let gross_amount = this.getNumberFormatForAmount(val);
    this.changeVoucher.gross_amount = (+gross_amount || 0).toLocaleString(this.$i18n.locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });

    //this.changeVoucher.gross_amount =val.toLocaleString(this.$i18n.locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 });
    //this.changeVoucher.gross_amount = +format * 100;
    this.changeVoucher.net_amount = ((((+gross_amount || 0) / (1 + this.taxRate / 100)) * 100) / 100).toLocaleString(
      this.$i18n.locale,
      { maximumFractionDigits: 2, minimumFractionDigits: 2 }
    );

    console.log('this.changeVoucher.net_amount :>> ', this.changeVoucher.net_amount);
    console.log(
      'this.changeVoucher.net_amount 2:>> ',
      NumberUtils.round((((this.changeVoucher.gross_amount || 0) / (1 + this.taxRate / 100)) * 100) / 100, 2)
    );
    // this.changeVoucher.net_amount = +format * 100;
  }

  changeTaxRate(val: string) {
    console.log('val :>> ', val);
    val = val.replace(',', '.');
    if (val.endsWith('.')) {
      val = val.slice(0, -1);
    }
      
    let net_amount = this.getNumberFormatForAmount(this.changeVoucher.net_amount);
    let gross_amount = this.getNumberFormatForAmount(this.changeVoucher.gross_amount);
    
    this.changeVoucher.net_amount = ((((+gross_amount || 0) / (1 + +val / 100)) * 100) / 100).toLocaleString(
      this.$i18n.locale,
      { maximumFractionDigits: 2, minimumFractionDigits: 2 }
    );
  }

  changeVoucherNetto(val: string) {
    let format = val.replace(',', '.');
    let splitted = format.split('.');
    if (splitted.length > 1) {
      let last = splitted.pop();
      format = splitted.join('') + '.' + last;
    }
    if (format.endsWith('.')) {
      format = format.slice(0, -1);
    }
    
    let net_amount = this.getNumberFormatForAmount(val);

    this.changeVoucher.net_amount = (+net_amount).toLocaleString(this.$i18n.locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
   
    this.changeVoucher.gross_amount = (((+net_amount || 0) * (1 + this.taxRate / 100) * 100) / 100).toLocaleString(
      this.$i18n.locale,
      { maximumFractionDigits: 2, minimumFractionDigits: 2 }
    );
  }

  getNumberFormatForAmount(valAmount: string) {
    if (valAmount == undefined) return 0;
    let formatAmount = valAmount.toString().indexOf(',') != -1 ? valAmount.replace(',', '.') : valAmount;    
    if (formatAmount.toString().indexOf('.') !== -1) {
      let splitted = formatAmount.toString().split('.');
      if (splitted.length > 1) {
        let last = splitted.pop();
        formatAmount = splitted.join('') + '.' + last;
      }
      if (formatAmount.endsWith('.')) {
        formatAmount = formatAmount.slice(0, -1);
      }
    }
    return formatAmount;
  }

  startInvoiceSearch(val: string) {
    if (val.length >= 3 && !this.changeVoucher.sender_number) {
      const payload = {
        searchWord: val,
        zrNummer: this.zrNummer,
        isUserInAzureAdminGroup: this.isUserInAzureAdminGroupGetter,
      };
      this.actionGetInvoicesForVoucher(payload)
        .then((result: any) => {
          this.voucherInvoices = result;
        })
        .catch((err: any) => {
          logger.error(err);
        });
    }
    console.log('val :>> ', val);
  }

  invoice: any = {};
  // betragNetto = 0;
  get betragNetto() {
    return (
      (this.changeVoucher.net_amount || 0).toLocaleString(this.$i18n.locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + ' €'
    );
  }

  get betragNettoNoSign() {
    return (this.changeVoucher.net_amount || 0).toLocaleString(this.$i18n.locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }

  // use `19` for `steursatz` if it is NULL on backend
  get taxRate() {
    // return this.changeVoucher.tax_rate === null || this.changeVoucher.tax_rate === undefined
    //   ? 19
    //   : this.changeVoucher.tax_rate;
    if (this.changeVoucher.tax_rate === null || this.changeVoucher.tax_rate === undefined) {
      this.changeVoucher.tax_rate = 0;
    }
    return this.changeVoucher.tax_rate;
  }
  // localizedTaxRate = 0;
  get localizedTaxRate() {
    return this.taxRate.toLocaleString(this.$i18n.locale) + ' %'; // || (19).toLocaleString(this.$i18n.locale);
  }

  get taxAmountValue() {
    let result = this.changeVoucher.tax_amount;
    if (!result) {
      let net_amount = this.getNumberFormatForAmount(this.changeVoucher.net_amount);
      let gross_amount = this.getNumberFormatForAmount(this.changeVoucher.gross_amount);
      result = ((+gross_amount || 0) - (+net_amount || 0)).toLocaleString(this.$i18n.locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    }
    return result;
  }

  // taxAmount = 0;
  get taxAmount() {
    return this.taxAmountValue.toLocaleString(this.$i18n.locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }
  // localizedBetragBrutto = 0;
  get localizedBetragBrutto() {
    return (
      (this.changeVoucher.gross_amount || 0).toLocaleString(this.$i18n.locale, {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      }) + ' €'
    );
  }

  //#region GSP-125 Tried approach with `betragNettoNumber/betragBruttoNumber` getters but DID NOT work at once invistigate or delete this block

  get betragNettoNumber() {
    // return this.zrDocument.betrag_Brutto / (1 + this.zrDocument.steursatz);

    // should `Round` (not `Trunc`) to avoid 1 cent difference with actual `Beleg` document
    const result = NumberUtils.round(
      (((this.changeVoucher.gross_amount || 0) / (1 + this.taxRate / 100)) * 100) / 100,
      2
    ); // return 2 decimal digits with rounding
    return result;
  }

  // get betragNetto() {
  //   // return this.zrDocument.betrag_Brutto / (1 + this.zrDocument.steursatz);
  //   return (
  //     this.betragNettoNumber.toLocaleString(this.$i18n.locale, { maximumFractionDigits: 2, minimumFractionDigits: 2 }) +
  //     ' €'
  //   );
  // }
  get betragBruttoNumber() {
    // return this.zrDocument.betrag_Brutto / (1 + this.zrDocument.steursatz);

    // should `Round` (not `Trunc`) to avoid 1 cent difference with actual `Beleg` document
    // const result = NumberUtils.round(((this.changeVoucher.net_amount || 0) * (1 + (this.changeVoucher.tax_rate || 0) / 100) * 100) / 100, 2); // return 2 decimal digits with rounding
    const result = NumberUtils.round(
      (((this.changeVoucher.net_amount || 0) / (100 - (this.changeVoucher.tax_rate || 0))) * 100) / 100,
      2
    ); // return 2 decimal digits with rounding
    return result;
  }
  //#endregion

  get localizedBetragBruttoNoSign() {
    return (this.changeVoucher.gross_amount || 0).toLocaleString(this.$i18n.locale, {
      maximumFractionDigits: 2,
      minimumFractionDigits: 2,
    });
  }
  localizedInstantAbzug = 0;
  localizedTotalAbzug = 0;
  // imageReceipt = { height: 100 };
  isShowBackupRechnungImage = true;
  private belegeType = 'Rechnung'; // !! type should `Rechnung` since different logic on backend for `Rechnung` type for downloading file
  showBackupImage(documentType: string) {
    this.isShowBackupRechnungImage = true;
  }
  get imageReceipt() {
    return {
      src: 'belege_stub_big.png',
      height: this.dynamicImageSize.height,
      width: this.dynamicImageSize.width,
    };
  }
  private createIcon = {
    src: 'aea_create_2.svg',
    header: '',
    height: 100,
    width: 100,
  };

  get dynamicImageSize() {
    if (this.$vuetify.breakpoint.xlOnly) {
      return { height: 610, width: 450 };
    } else if (this.$vuetify.breakpoint.lgOnly && this.$vuetify.breakpoint.width > 1580) {
      return { height: 610, width: 450 };
    } else if (this.$vuetify.breakpoint.lgOnly && this.$vuetify.breakpoint.width < 1580) {
      return { height: 600, width: 410 };
    } else if (this.$vuetify.breakpoint.mdOnly) {
      return { height: 410, width: 250 };
    }

    return { height: 515, width: 350 };
  }
  hasRecords = false;
  isRecordLoaded = true;

  get idAndInvoiceNumber() {
    return `${this.changeVoucher.document_number} / ${this.changeVoucher.external_document_reference}`;
  }

  get currencies() {
    let currenciesList = [
      { id: 'EUR', name: 'EUR' },
      { id: 'CHF', name: 'CHF' },
      { id: 'euro', name: 'EUR' },
    ];
    return currenciesList;
  }

  private allowedDates(date: string) {
    const dateValue = new Date(date);
    let isFriday = dateValue.getDay() === 5;
    const today = new Date();
    let twoWeeksFromToday = new Date(today.getTime() + 12096e5 - 864e5); // `12096e5` is 14 days in milliseconds // (GSP-098) use 13 days (not 14) for 2 weeks after to be able to select again the Friday that 2 weeks from current Friday (otherwise it will be selected by default but you could not select it again manually)
    //`864e5` = 86400000 is 1 day in milliseconds

    if (this.isTodayMondayOrTuesday()) {
      twoWeeksFromToday = new Date(today.getTime() + 6048e5); // add 7 days in milliseconds to make next Friday valid (in 11 or 10 days (from Mon and Tue), not in 18 and 17 days as was before)
    }

    const isDateMoreThan2Weeks = dateValue > twoWeeksFromToday;

    return isFriday && isDateMoreThan2Weeks;
  }

  private allowedBelegDate(date: string) {
    const dateValue = new Date(date);
    const today = new Date();
    const isDateMoreThan2Weeks = dateValue < today;
    return isDateMoreThan2Weeks;
  }

  private isTodayMondayOrTuesday() {
    const today = new Date();
    const isMondayOrTuesday = today.getDay() === 1 || today.getDay() == 2;
    return isMondayOrTuesday;
  }

  // `5` is Friday, `0` Sunday
  getNextDayOfWeek(date: any, dayOfWeek: any = 5) {
    var resultDateInMilliseconds = new Date(date.getTime());

    let addDays = 14;
    if (this.isTodayMondayOrTuesday()) {
      addDays = 7;
    }

    resultDateInMilliseconds.setDate(date.getDate() + ((7 + dayOfWeek - date.getDay()) % 7) + addDays);

    return resultDateInMilliseconds;
  }

  selectMember(value: any) {
    this.changeVoucher.receiver_number = value.id;
    this.changeVoucher.name_mitglied = value.name;
  }

  get aaeTypes() {
    // let i18key = aaeTypeList.find((x) => x.index == this.changeVoucher.aae_typ)?.i18nKey;
    // let formattedResult = i18key ? `${this.$t(i18key)} (${this.changeVoucher.aae_typ})` : this.changeVoucher.aae_typ;
    let list = aaeTypeList.map((x: AaeType) => {
      return { id: x.id, index: x.index, i18nKey: x.i18nKey, name: `${this.$t(x.i18nKey)}` };
    });

    if (!this.changeVoucher.external_document_reference) {
      list = list.filter(
        (x: AaeType) =>
          x.i18nKey.endsWith(AaeTypeNameKey.credit_note) ||
          x.i18nKey.endsWith(AaeTypeNameKey.debit) ||
          x.i18nKey.endsWith(AaeTypeNameKey.bonus_minus) ||
          x.i18nKey.endsWith(AaeTypeNameKey.bonus_plus)
      );
    } else {
      // filter `aae-type` depending on Invoice BelegTyp
      if ([1, 2, 8, 9, 10, 11, 12, 14, 15, 20].includes(this.currentInvoiceTypeNum)) {
        list = list.filter((x: AaeType) => !x.i18nKey.endsWith(AaeTypeNameKey.cancel_reverse));
      } else if ([3, 13].includes(this.currentInvoiceTypeNum)) {
        list = list.filter((x: AaeType) => x.i18nKey.endsWith(AaeTypeNameKey.cancel_reverse));
      } else if ([4, 5].includes(this.currentInvoiceTypeNum)) {
        list = list.filter(
          (x: AaeType) => x.i18nKey.endsWith(AaeTypeNameKey.cancel) || x.i18nKey.endsWith(AaeTypeNameKey.rebooking)
        );
      } else if (this.currentInvoiceTypeNum == 6) {
        list = [];
      }
    }

    let formattedResult = this.changeVoucher.aae_typ;
    return list;
  }

  get aeeTypeValue() {
    // TODO create helper
    // let i18key = aeeTypeList.find((x) => x.index == this.changeVoucher.aae_typ)?.i18nKey;
    // let formattedResult = i18key ? `${this.$t(i18key)} (${this.changeVoucher.aae_typ})` : this.changeVoucher.aae_typ;
    let formattedResult = this.changeVoucher.aae_typ;
    return formattedResult;
  }

  private changeReasonsValueGet(index: number) {
    return this.changeReasons.find((x: any) => x.index == index)?.name;
  }
  get changeReasons() {
    // let i18key = aaeTypeList.find((x) => x.index == this.changeVoucher.aae_typ)?.i18nKey;
    // let formattedResult = i18key ? `${this.$t(i18key)} (${this.changeVoucher.aae_typ})` : this.changeVoucher.aae_typ;
    let list = changeReasonList.map((x: any) => {
      return { index: x.index, i18nKey: x.i18nKey, name: `${this.$t(x.i18nKey)}` };
    });
    console.log('changeReasonList :>> ', changeReasonList);

    console.log('this.changeVoucher.aae_typ :>> ', this.changeVoucher.aae_typ);
    if (this.changeVoucher.aae_typ == 2 || this.changeVoucher.aae_typ == 10) {
      list = list.filter(
        (x: ChangeReasonType) =>
          !x.i18nKey.endsWith(ChangeReasonNameKey.not_selectable) &&
          !x.i18nKey.endsWith(ChangeReasonNameKey.value_date_agreement)
      );
    } else if (this.changeVoucher.aae_typ == 3) {
      list = list.filter(
        (x: ChangeReasonType) =>
          !x.i18nKey.endsWith(ChangeReasonNameKey.not_selectable) &&
          !x.i18nKey.endsWith(ChangeReasonNameKey.supplier_credit_note) &&
          !x.i18nKey.endsWith(ChangeReasonNameKey.bonus_credit) &&
          !x.i18nKey.endsWith(ChangeReasonNameKey.freight_allowance) &&
          !x.i18nKey.endsWith(ChangeReasonNameKey.contract_penalty) &&
          !x.i18nKey.endsWith(ChangeReasonNameKey.value_date_agreement)
      );
    } else if (this.changeVoucher.aae_typ == 5 || this.changeVoucher.aae_typ == 21) {
      list = list.filter((x: ChangeReasonType) => x.i18nKey.endsWith(ChangeReasonNameKey.not_selectable));
      this.changeVoucher.change_reason = 0;
    } else if (this.changeVoucher.aae_typ == 4) {
      list = list.filter((x: ChangeReasonType) => x.i18nKey.endsWith(ChangeReasonNameKey.value_date_agreement));
      this.changeVoucher.change_reason = 10;
    } else if (this.changeVoucher.aae_typ == 8 || this.changeVoucher.aae_typ == 18) {
      list = list.filter((x: ChangeReasonType) => x.i18nKey.endsWith(ChangeReasonNameKey.contract_penalty));
      this.changeVoucher.change_reason = 8;
    } else if (this.changeVoucher.aae_typ == 9 || this.changeVoucher.aae_typ == 19) {
      list = list.filter((x: ChangeReasonType) => x.i18nKey.endsWith(ChangeReasonNameKey.bonus_credit));
      this.changeVoucher.change_reason = 6;
    }
    //|| x.i18nKey.endsWith(ChangeReasonNameKey.contract_penalty)
    return list;
  }

  get internalStatuses() {
    // let i18key = aaeTypeList.find((x) => x.index == this.changeVoucher.aae_typ)?.i18nKey;
    // let formattedResult = i18key ? `${this.$t(i18key)} (${this.changeVoucher.aae_typ})` : this.changeVoucher.aae_typ;
    let list = changevoucherInternalStatusList.map((x: any) => {
      return { index: x.index, i18nKey: x.i18nKey, name: `${this.$t(x.i18nKey)}` };
    });

    return list;
  }

  get isEmptyChangeVoucher() {
    return this.changeVoucher.id == '';
  }

  get image() {
    return {
      // src: 'img_changeVoucher.png',
      // header: '',
      // width: 350,
      // height: 200,
    };
  }

  get supplierAndAeeFilled() {
    return !!this.changeVoucher.sender_number && !!this.changeVoucher.aae_typ;
  }
  get invoiceAndAeeFilled() {
    return !!this.changeVoucher.document_number && !!this.changeVoucher.aae_typ;
  }

  // getTitle(voucher: ChangeVoucher) {
  getTitle() {
    let voucher = this.changeVoucher;
    if (voucher.bearbeit_von != null || voucher.erstellt_von != undefined) {
      let result = this.$t('edited_by') + ':  ' + (voucher.bearbeit_von || voucher.erstellt_von) + ', ';
      console.log(' result getTitle :>> ', result);
      return result;
    } else {
      return '';
    }
  }

  //#region Logic related to `status`

  get statuses() {
    return {
      0: { text: this.$t('status_chip_voucher.draft'), class: 'none' }, // gray
      1: { text: this.$t('status_chip_voucher.waiting'), class: 'blue' },
      2: { text: this.$t('status_chip_voucher.submitted'), class: 'yellow' },
      3: { text: this.$t('status_chip_voucher.booked'), class: 'green' },
      4: { text: this.$t('status_chip_voucher.rejected'), class: 'red' },
    };
  }

  // temp logic to see colors for Chaslau
  increaseSt() {
    this.changeVoucher.status++;
    if (this.changeVoucher.status > 4) {
      this.changeVoucher.status = 0;
    }
  }

  changeWvDate(value: any) {
    this.changeVoucher.wv_date = value || undefined; // new Date(value).toJSON();
  }

  changeDueDate(value: any) {
    this.changeVoucher.due_date = value || undefined; // new Date(value).toJSON();
  }

  //#endregion

  async onClickedUpdate() {
    this.isLoading = true;
    const result = await this.observerForm.validate();
    if (!result) {
      this.isLoading = false;
      return;
    }
    if (!this.changeVoucher.erfassungs_datum) {
      this.changeVoucher.erfassungs_datum = DateUtils.getIsoDateNoTimeFromNow();
    }

    this.changeVoucher.net_amount = this.getNumberFormatForAmount(this.changeVoucher.net_amount);
    this.changeVoucher.gross_amount = this.getNumberFormatForAmount(this.changeVoucher.gross_amount);

    if (this.changeVoucher.id != 0 && this.changeVoucher.status != 4) {
      await this.actionUpdateChangeVoucher(this.changeVoucher).then((resp: any) => {
        this.changeVoucher = resp.result;

        this.isLoading = false;
        this.changeVoucher.id = resp.idL;
        console.log('resp.idL :>> ', resp.idL);
        console.log('resp.res :>> ', resp.result.id);
        if (resp.result.id != this.changeVoucherId) {
          this.$router.push({
            name: ROUTES.changeVoucher,
            params: { changeVoucherId: resp.result.id },
          });
        }

        this.isShowBackupRechnungImage = false;
        // this.loadChangeVoucher();
      });
    } else {
      this.changeVoucher.id = 0;
      this.changeVoucher.status = 0;
      await this.actionCreaateChangeVoucher(this.changeVoucher).then((resp: any) => {
        this.changeVoucher = resp.result;
        this.changeVoucher.dms_nr_invoice = this.dmsInvoiceNr; // (GSP-162) populate `dms_nr_invoice` when create `changeVoucher`
        this.isLoading = false;
        this.changeVoucher.id = resp.idL;
        console.log('resp.idL :>> ', resp.idL);
        console.log('resp.res :>> ', resp.result.id);
        this.$router.push({
          name: ROUTES.changeVoucher,
          params: { changeVoucherId: resp.result.id },
        });
        this.isShowBackupRechnungImage = false;
        // this.loadChangeVoucher();
      });
    }
  }

  goToVoucherList() {
    this.$router.push({
      name: ROUTES.changeVouchers,
    });
  }
  onClickedClose() {
    this.$router.push({
      name: ROUTES.changeVouchers,
    });
  }

  get changeVoucherId() {
    return this.$route.params['changeVoucherId'];
  }

  async loadChangeVoucher() {
    await this.actionGetChangeVoucher(this.changeVoucherId)
      .then((response: any) => {
        console.log('get changeVoucher response :>> ', response);
        this.changeVoucher = Object.assign({}, response);
        this.activeTab = 'data2';
        this.isShowBackupRechnungImage = false;
        // this.titleInfo = this.changeVoucher.name;
      })
      .then(() => {})
      .finally(() => {
        this.isFormLoaded = true;
      });
  }

  //#region Loading autocompletes logic

  members = [];
  suppliers = [];

  private loadingSuppliers = false;
  private async getSuppliers() {
    if (this.getInvoiceSuppliers?.length > 0) {
      this.suppliers = this.getInvoiceSuppliers;
      this.loadingSuppliers = false;
    } else {
      this.loadingSuppliers = true;
      // let payload = this.setInvoiceSearchFormFiltersPayload();
      await this.actionGetInvoiceSuppliers()
        .then((result: any) => {
          if (this.supplierTooltipInfo) {
            // ONLY_DEV (GSP-117) for now loading tooltip only for dev            
            this.suppliers = result.map((item: any) =>
              Object.assign(
                item,
                this.supplierTooltipInfo.find((y: any) => y.zrNummer == item.id)
              )
            );
          } else {
            this.suppliers = result;
          }
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingSuppliers = false;
        });
    }
  }

  changevoucherSearchData = cngvoucherSearchData.defaultData();
  private loadingMembers = false;
  private async getMembers() {
    if (this.getInvoiceMembers?.length > 0) {
      this.members = this.getInvoiceMembers;
    } else {
      this.loadingMembers = true;
      const payload = Object.assign({}, this.changevoucherSearchData);
      payload.isUserInAzureAdminGroup = this.isUserInAzureAdminGroupGetter;
      payload.isUserInOnlyGs = this.isUserOnlyGs;
      payload.zrNummer = this.getZrNummerDependOnRole();
      await this.actionGetInvoiceMembers(payload)
        .then((result: any) => {
          this.members = result;
        })
        .catch((err: any) => {
          logger.error(err);
        })
        .finally(() => {
          this.loadingMembers = false;
        });
    }
  }

  getZrNummerDependOnRole() {
    let result = this.isUserOnlyGs ? this.zrNummerWithRoles.voucherZrNummer + '' || '-' : this.zrNummer;
    return result;
  }

  //#endregion

  //#region Next/previous logic
  get currentIdPosition() {
    console.log('changeVouchers :>> ', this.changeVouchers);
    return this.changeVouchers?.findIndex((x: any) => x.id == this.changeVoucherId);
  }

  get isFirstChangeVoucher() {
    return this.currentIdPosition == 0;
  }

  get isLastChangeVoucher() {
    return this.currentIdPosition == this.changeVouchers.length - 1;
  }

  goToPrev() {
    const prevId = this.changeVouchers[this.currentIdPosition - 1].id;

    this.$router.push({
      name: ROUTES.changeVoucher,
      params: { changeVoucherId: prevId },
    });
    this.loadChangeVoucher();
  }

  goToNext() {
    const nextId = this.changeVouchers[this.currentIdPosition + 1].id;

    this.$router.push({
      name: ROUTES.changeVoucher,
      params: { changeVoucherId: nextId },
    });
    this.loadChangeVoucher();
  }
  //#endregion

  //#region logic to show pdf document
  get hasIframeWithDocument() {
    return this.isRecordLoaded && document.querySelector(`iframe#${this.belegeType}`);
  }

  async screenshot() {
    let fstPageDataUrl = '';
    if (!this.hasIframeWithDocument) {
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAQAAAAnOwc2AAAAD0lEQVR42mNkwAIYh7IgAAVVAAuInjI5AAAAAElFTkSuQmCC'; // white transparent square 10x10 pixels (generated here https://png-pixel.com/)
    }

    const iframe1stPage: any = (
      document.querySelector(`iframe#${this.belegeType}`) as any
    ).contentDocument!.querySelector('#viewer > div[data-page-number="1"] .canvasWrapper')!;
    await html2canvas(iframe1stPage, {
      allowTaint: true,
      useCORS: true,
    })
      .then((canvas: any) => {
        // It will return a canvas element
        let image = canvas.toDataURL('image/png', 0.75);
        fstPageDataUrl = image;
      })
      .catch((e: any) => {
        // Handle errors
        console.log(e);
      });
    return fstPageDataUrl;
  }

  private pdfScaleInitialValue = '';
  private pdfScaleElem: any = {};
  changePdfScale() {
    if (!this.hasIframeWithDocument) return;

    const size200percent: any = (document.querySelector(
      `iframe#${this.belegeType}`
    ) as any)!.contentDocument!.querySelector('#scaleSelect')!;
    // console.log('size200percent :>> ', size200percent);
    if (size200percent) {
      this.pdfScaleElem = size200percent;
      this.pdfScaleInitialValue = size200percent.value;

      size200percent.value = 1.5;
      size200percent.dispatchEvent(new Event('change'));
    }
  }
  returnInitialPdfScale() {
    if (!this.hasIframeWithDocument) return;

    this.pdfScaleElem.value = this.pdfScaleInitialValue;
    this.pdfScaleElem.dispatchEvent(new Event('change'));
  }
  //#endregion

  async pdfDownload() {
    //actionGetChangeVoucherDetailsPdfReport
    this.isGeneratingPdfReport = true;
    this.changePdfScale();
    delay(1200).then(async (result) => {
      let pdfScreenDataUrl = await this.screenshot();

      delay(1200).then(() => {
        let payload = this.changeVoucher;
        payload.pdfScreen = pdfScreenDataUrl;
        payload.DateTimeTitle =
          this.$i18n.locale == 'de' ? new Date().toLocaleString('de') : new Date().toLocaleString('en');
        payload.lang = this.$i18n.locale;
        this.actionGetChangeVoucherDetailsPdfReport(payload).finally(() => {
          this.isGeneratingPdfReport = false;
        });

        delay(500).then(() => {
          this.returnInitialPdfScale();
        });
      });
    });
  }

  private documentAeaName(value: number) {
    let i18key = changevoucherTypeList.find((x) => x.index == value)?.i18nKey;
    let formattedResult = i18key ? `${this.$t(i18key)}` : value;
    return formattedResult;
  }
}
