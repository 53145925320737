import { render, staticRenderFns } from "./document-upload.html?vue&type=template&id=45d20ee6&scoped=true&"
import script from "./document-upload.ts?vue&type=script&lang=ts&"
export * from "./document-upload.ts?vue&type=script&lang=ts&"
import style0 from "./document-upload.scss?vue&type=style&index=0&id=45d20ee6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45d20ee6",
  null
  
)

/* custom blocks */
import block0 from "./document-upload.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2F_common%2Fdocument-upload%2Fdocument-upload.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
installComponents(component, {VCard,VFileInput})
