import { render, staticRenderFns } from "./d4y-table-mini.html?vue&type=template&id=72b06be4&scoped=true&"
import script from "./d4y-table-mini.ts?vue&type=script&lang=ts&"
export * from "./d4y-table-mini.ts?vue&type=script&lang=ts&"
import style0 from "./d4y-table-mini.scss?vue&type=style&index=0&id=72b06be4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "72b06be4",
  null
  
)

/* custom blocks */
import block0 from "./d4y-table-mini.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fd4y-table-mini%2Fd4y-table-mini.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VEditDialog } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VCard,VCardText,VCardTitle,VDataTable,VEditDialog,VIcon,VImg,VSpacer,VTextField,VToolbar})
